import { useCallback, useEffect, useState } from 'react';

export const useForceUpdate = (afterUpdate?: () => void) => {
  const [token, setToken] = useState(0);
  useEffect(()=>{
    if (token !== 0){
      afterUpdate && afterUpdate();
    }
  }, [token])
  return useCallback(() => setToken(state => state + 1), []);
};
